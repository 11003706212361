import { useRef, useCallback } from 'react'

import { DebounceFunction } from './types'

const useDebounce = <T,>(
  callback: (params: T) => void,
  delay: number
): DebounceFunction<T> => {
  const timeoutRef = useRef<NodeJS.Timeout>()

  return useCallback(
    (params: T) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        callback(params)
      }, delay)
    },
    [callback, delay]
  )
}

export default useDebounce
