import { Box, Link, styled } from '@pnld/components-web'

const HeaderItem = styled(Box, {
  baseStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 3,
    borderRadius: 'none',
    color: 'white',
    height: 12,
    width: 24,
    maxWidth: '100%',
    borderBottom: '1px solid',
  },
})

const NavItem = styled(Link, {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    p: 3,
    cursor: 'pointer',
    borderBottom: '1px solid',
    textDecoration: 'none',
  },
})

export default {
  HeaderItem,
  NavItem,
}
