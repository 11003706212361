import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Stack, Flex, Image, Typography, Button } from '@pnld/components-web'

import LogoFNDE from '@/assets/svg/fnde/mono.svg'
import LogoMEC from '@/assets/svg/mec/mono.svg'
import LogoNEES from '@/assets/svg/nees/mono.svg'
import LogoSPA from '@/assets/svg/spa/colorful.svg'

const LoginAccessDenied: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Stack minH="100vh" bg="brand.highlight.dark">
      <Flex justify="center" align="center" flex={1} p={{ base: 2, md: 0 }}>
        <Stack
          width="596px"
          bg="brand.neutral.white"
          borderRadius="md"
          padding={16}
          gap={16}
        >
          <Image src={LogoSPA} h="75px" />
          <Stack gap={4}>
            <Typography
              variant="s-md"
              color="brand.error.dark"
              textAlign="center"
            >
              Usuário não identificado em nossa base.
            </Typography>
            <Typography
              variant="b-lg"
              textAlign="center"
              color="brand.primary.dark_2"
            >
              Nossa equipe revisou suas credenciais e, infelizmente, sua
              solicitação não foi autorizada. Verifique se usou as credenciais
              informadas no pré-cadastro ou entre em contato com seu
              coordenador.
            </Typography>
            <Flex justify="center">
              <Button
                label="Entrar com outro usuário"
                backgroundColor="brand.primary.dark_2"
                _hover={{ backgroundColor: 'brand.primary.dark_1' }}
                onClick={() => navigate('/login')}
              />
            </Flex>
          </Stack>
        </Stack>
      </Flex>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify="center"
        gap={{ base: 0, md: 8 }}
        paddingBottom={4}
      >
        <Image src={LogoNEES} height="80px" objectFit="contain" />
        <Image
          src={LogoFNDE}
          height={{ base: '32px', md: '40px' }}
          objectFit="contain"
        />
        <Image
          src={LogoMEC}
          height={{ base: '72px', md: '96px' }}
          objectFit="contain"
        />
      </Flex>
    </Stack>
  )
}

export default LoginAccessDenied
