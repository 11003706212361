import http, { httpWithoutMiddleware } from '@/api/http'

import {
  TGetEducationStagesResponse,
  TGetPredictionsYearsResponse,
} from './types'

export const getEducationStages = async () => {
  const response = await httpWithoutMiddleware.get<TGetEducationStagesResponse>(
    '/auxiliary/education_stages/'
  )

  return response.data
}

export const getPredictionsYears = async () => {
  const response = await http.get<TGetPredictionsYearsResponse>(
    '/auxiliary/prediction_years/'
  )

  return response.data
}
