import React from 'react'

import { Stack, Flex, Typography, IconButton } from '@pnld/components-web'

import { ISlidesDisplayProps } from './types'

const SlidesDisplay = ({ items }: ISlidesDisplayProps) => {
  const [page, setPage] = React.useState(0)

  const previousPage = () => {
    setPage(page - 1)
  }

  const nextPage = () => {
    setPage(page + 1)
  }

  return (
    <Stack gap={4}>
      <Flex justify="space-between">
        <Typography variant="h-xs" color="brand.primary.dark_2">
          {items[page]?.title}
        </Typography>
        <Flex gap={2}>
          <IconButton
            iconName="chevron-left"
            size="sm"
            variant="tertiary"
            aria-label="Anterior"
            disabled={page === 0}
            onClick={previousPage}
          />
          <IconButton
            iconName="chevron-right"
            size="sm"
            variant="tertiary"
            aria-label="Próximo"
            disabled={page === items.length - 1}
            onClick={nextPage}
          />
        </Flex>
      </Flex>
      {items[page] && <>{items[page].content}</>}
    </Stack>
  )
}

export default SlidesDisplay
