import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Stack,
  Flex,
  Image,
  Typography,
  Button,
  Input,
  Form,
  useToast,
} from '@pnld/components-web'

import { useMutationPostAuthLocalLogin } from '@/api/auth/local/mutations'
import LogoFNDE from '@/assets/svg/fnde/mono.svg'
import LogoMEC from '@/assets/svg/mec/mono.svg'
import LogoNEES from '@/assets/svg/nees/mono.svg'
import LogoSPA from '@/assets/svg/spa/colorful.svg'
import { getErrorDetails } from '@/utils/error'

const LoginDevelopment: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const { mutate: postAuthLocalLogin, isLoading: isLoadingAuthLocalLogin } =
    useMutationPostAuthLocalLogin()

  const handleSubmit = (data: { cpf: string; password: string }) => {
    const { cpf, password } = data
    const cpfWithoutMask = cpf.replace(/\D/g, '')

    postAuthLocalLogin(
      {
        dsCpf: cpfWithoutMask,
        dsPassword: password,
      },
      {
        onSuccess: () => {
          navigate('/')
        },
        onError: error => {
          const statusCode = error.response?.status

          if (statusCode === 401) {
            navigate('/login/acesso-negado')

            return
          }

          toast({
            status: 'error',
            title: 'Ops!',
            description: getErrorDetails(error),
            isClosable: true,
          })
        },
      }
    )
  }

  const handleGoBack = () => {
    navigate('/login')
  }

  return (
    <Stack minH="100vh" bg="brand.highlight.dark">
      <Flex justify="center" align="center" flex={1} p={{ base: 2, md: 0 }}>
        <Stack
          width="596px"
          bg="brand.neutral.white"
          borderRadius="md"
          padding={16}
          gap={8}
        >
          <Image src={LogoSPA} h="75px" />
          <Typography
            variant="h-xs"
            textAlign="center"
            color="brand.primary.dark_1"
          >
            Seja bem-vindo(a) ao
            <br />
            Sistema de Predição do Alunado
          </Typography>

          <Form
            onSubmit={handleSubmit}
            initialValues={{ cpf: '', password: '' }}
          >
            <Stack gap={6}>
              <Input
                label="CPF"
                name="cpf"
                mask="999.999.999-99"
                placeholder="Digite seu CPF"
                isRequired
              />

              <Input
                label="Senha"
                name="password"
                type="password"
                placeholder="Digite sua senha"
                isRequired
              />

              <Flex direction="column" gap={4}>
                <Button
                  type="submit"
                  label="Entrar como desenvolvedor"
                  backgroundColor="brand.primary.dark_2"
                  _hover={{ backgroundColor: 'brand.primary.dark_1' }}
                  isLoading={isLoadingAuthLocalLogin}
                  width="full"
                />
                <Button
                  label="Voltar para a tela de login"
                  backgroundColor="#A82E2F"
                  _hover={{ backgroundColor: '#C63637' }}
                  width="full"
                  onClick={handleGoBack}
                />
              </Flex>
            </Stack>
          </Form>
        </Stack>
      </Flex>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify="center"
        gap={{ base: 0, md: 8 }}
        paddingBottom={4}
      >
        <Image src={LogoNEES} height="80px" objectFit="contain" />
        <Image
          src={LogoFNDE}
          height={{ base: '32px', md: '40px' }}
          objectFit="contain"
        />
        <Image
          src={LogoMEC}
          height={{ base: '72px', md: '96px' }}
          objectFit="contain"
        />
      </Flex>
    </Stack>
  )
}

export default LoginDevelopment
