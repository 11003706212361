import React from 'react'

import * as yup from 'yup'

import { Stack } from '@chakra-ui/react'
import {
  Typography,
  Button,
  Form,
  UploadFile,
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from '@pnld/components-web'

import { useSendingsController } from './controller'

interface ISendDataModalProps {
  isOpen: boolean
  onClose: () => void
}

const SendDataModal = ({ isOpen, onClose }: ISendDataModalProps) => {
  const { formikRef, handleSubmit, sendData } = useSendingsController({
    onClose,
  })
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          <Typography variant="h-xs" color="brand.neutral.dark_2">
            Enviar dados para base de predição
          </Typography>
        </ModalHeader>
        <ModalBody as={Stack} gap={4}>
          <Typography variant="b-sm">
            Para realizar uma nova predição, insira o arquivo completo de
            microdados do censo escolar mais recente disponibilizado pelo INEP.
          </Typography>
          <Form
            innerRef={formikRef}
            onSubmit={handleSubmit}
            validationSchema={yup.object().shape({
              file: yup.mixed().required('O arquivo é obrigatório'),
            })}
          >
            <UploadFile
              label="Selecione seu arquivo"
              name="file"
              accept=".csv"
              maxSize={15728640}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button label="Cancelar" variant="secondary" onClick={onClose} />
          <Button label="Enviar dados" variant="primary" onClick={sendData} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SendDataModal
