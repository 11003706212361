import { useUser } from '@/contexts/UserContext'

import { TTemplateSidebarProps } from './types'

export const useSidebarItems = () => {
  const { userProfile, isLoading } = useUser()

  if (isLoading) {
    return {
      linkItems: [],
    } as TTemplateSidebarProps
  }

  const defaultItems = [
    {
      name: 'Predições',
      icon: 'folder-open',
      to: '/predicoes',
    },
    {
      name: 'Envios',
      icon: 'upload',
      to: '/envios',
    },
  ]

  const adminItems = [
    {
      name: 'Usuários',
      icon: 'users',
      to: '/usuarios',
    },
  ]

  return {
    linkItems: [
      ...defaultItems,
      ...(userProfile === 'Admin' ? adminItems : []),
    ],
  } as TTemplateSidebarProps
}
