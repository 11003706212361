import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { getEducationStages, getPredictionsYears } from './services'
import {
  TGetEducationStagesResponse,
  TGetPredictionsYearsResponse,
} from './types'

export const useQueryGetEducationStages = (
  options?: UseQueryOptions<TGetEducationStagesResponse, AxiosError<IErrorDTO>>
) => {
  const queryFunction = () => getEducationStages()

  return useQuery<TGetEducationStagesResponse, AxiosError<IErrorDTO>>(
    useQueryGetEducationStages.queryKey,
    queryFunction,
    options
  )
}

useQueryGetEducationStages.queryKey = ['education_stages']

export const useQueryGetPredictionsYears = (
  options?: UseQueryOptions<TGetPredictionsYearsResponse, AxiosError<IErrorDTO>>
) => {
  const queryFunction = () => getPredictionsYears()

  return useQuery<TGetPredictionsYearsResponse, AxiosError<IErrorDTO>>(
    useQueryGetPredictionsYears.queryKey,
    queryFunction,
    options
  )
}

useQueryGetPredictionsYears.queryKey = ['predictions_years']
