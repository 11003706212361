import React, { useState } from 'react'

import { Box, Input, InputGroup } from '@chakra-ui/react'
import { Typography } from '@pnld/components-web'

const DateRangeFilter = ({
  onStartDateChange,
  onFinalDateChange,
}: {
  onStartDateChange: (date: string) => void
  onFinalDateChange: (date: string) => void
}) => {
  const [startDate, setStartDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [error, setError] = useState('')

  const validateDates = (start: string, end: string) => {
    if (!start || !end) {
      setError('')
      return true
    }

    const startDateTime = new Date(start).getTime()
    const finalDateTime = new Date(end).getTime()

    if (finalDateTime < startDateTime) {
      setError('Data final não pode ser menor que a data inicial')
      return false
    }

    if (finalDateTime - startDateTime > 1000 * 60 * 60 * 24 * 31) {
      setError('Intervalo de datas não pode ser maior que 31 dias')
      return false
    }

    if (start.length > 10 || end.length > 10) {
      setError('Data inválida')
      return false
    }

    setError('')
    return true
  }

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value
    setStartDate(newStartDate)

    if (validateDates(newStartDate, finalDate)) {
      onStartDateChange(newStartDate)
    }
  }

  const handleFinalDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFinalDate = e.target.value
    setFinalDate(newFinalDate)

    if (validateDates(startDate, newFinalDate)) {
      onFinalDateChange(newFinalDate)
    }
  }

  return (
    <Box>
      <InputGroup gap={4}>
        <Input
          placeholder="Data inicial"
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          max={finalDate || undefined}
        />
        <Input
          placeholder="Data final"
          type="date"
          value={finalDate}
          onChange={handleFinalDateChange}
          min={startDate || undefined}
        />
      </InputGroup>
      {error && (
        <Typography variant="b-sm" color="red.500" mt={2}>
          {error}
        </Typography>
      )}
    </Box>
  )
}

export default DateRangeFilter
