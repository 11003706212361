import React, { createContext, useContext, useMemo } from 'react'

import { useToast } from '@pnld/components-web'

import { useQueryGetMyAccount } from '@/api/users/account/queries'
import { getErrorDetails } from '@/utils/error'

interface IUserContextData {
  userProfile: string | undefined
  dsCpf: string | undefined
  isLoading: boolean
}

const UserContext = createContext<IUserContextData>({
  userProfile: undefined,
  dsCpf: undefined,
  isLoading: true,
})

export const UserContextProvider = ({ children }: React.PropsWithChildren) => {
  const toast = useToast()

  const { data: myAccount, isLoading } = useQueryGetMyAccount({
    staleTime: 1000 * 60 * 15,
    keepPreviousData: true,
    notifyOnChangeProps: ['data', 'error'],

    onError: error => {
      toast({
        title: 'Erro ao carregar dados do usuário',
        description: getErrorDetails(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    },
  })

  const contextValue = useMemo(
    () => ({
      userProfile: myAccount?.profile.noProfileName,
      dsCpf: myAccount?.dsCpf,
      isLoading,
    }),
    [myAccount, isLoading]
  )

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('Hook usado fora do contexto de UserContext')
  }

  return context
}
