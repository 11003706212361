import React, { useMemo } from 'react'

import {
  Box,
  Stack,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import {
  DataGrid,
  TColumnDef,
  Typography,
  Button,
  Form,
  useModal,
  Pagination,
  Icon,
} from '@pnld/components-web'

import DateRangeFilter from '@/components/DateRange'

import { useSendingsController } from './controller'
import SendDataModal from './SendDataModal'
import {
  TableSkeleton,
  PaginationSkeleton,
  EmptyState,
} from './skeletonSendings'
import { ISendings } from './types'

const Sendings = () => {
  const { isOpen, onClose, onOpen } = useModal()
  const {
    tableData,
    handleSearchChange,
    handleStartDateChange,
    handleFinalDateChange,
    isLoading,
    ...paginationProps
  } = useSendingsController({ onClose })

  const columns = useMemo<Array<TColumnDef<ISendings>>>(
    () => [
      {
        accessorKey: 'filename',
        header: 'Nome do arquivo',
        enableSorting: false,
      },
      {
        accessorKey: 'username',
        header: 'Nome do usuário',
        enableSorting: false,
      },
      {
        accessorFn: row => row.sendDate,
        header: 'Data de envio',
        enableSorting: false,
      },
    ],
    []
  )

  return (
    <Stack
      flexGrow={1}
      flex={1}
      p={5}
      boxShadow="md"
      borderRadius="base"
      gap={4}
      h="100%"
    >
      <Typography variant="h-xs" color="brand.primary.dark_1">
        Histórico de envios
      </Typography>
      <Form onSubmit={() => {}}>
        <Flex w="full" gap={4}>
          <Flex align="center">
            <Typography
              variant="b-sm"
              color="brand.primary.dark_1"
              whiteSpace="nowrap"
            >
              Filtrar por
            </Typography>
          </Flex>
          <Box w="full">
            <InputGroup gap={4}>
              <InputGroup>
                <Input
                  placeholder="Buscar por nome do arquivo"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleSearchChange(e.target.value)
                  }
                />
                <InputRightElement>
                  <Icon name="search" size="2xs" />
                </InputRightElement>
              </InputGroup>

              <DateRangeFilter
                onStartDateChange={handleStartDateChange}
                onFinalDateChange={handleFinalDateChange}
              />
            </InputGroup>
          </Box>
          <Button
            label="Enviar dados"
            variant="secondary"
            iconName="upload"
            onClick={onOpen}
          />
        </Flex>
      </Form>
      <Box overflowX="auto" flexGrow={1} h="100%">
        {isLoading ? (
          <TableSkeleton />
        ) : tableData.length === 0 ? (
          <EmptyState />
        ) : (
          <DataGrid columns={columns} data={tableData} hasPagination={false} />
        )}
      </Box>
      {isLoading ? (
        <PaginationSkeleton />
      ) : tableData.length > 0 ? (
        <Pagination {...paginationProps} />
      ) : null}
      <SendDataModal isOpen={isOpen} onClose={onClose} />
    </Stack>
  )
}

export default Sendings
