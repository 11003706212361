import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import {
  Flex,
  Grid,
  GridItem,
  Image,
  Button,
  Hide,
  Typography,
  Progress,
  useToast,
} from '@pnld/components-web'

import { useMutationPostAuthGovBr } from '@/api/auth/govbr/mutations'
import { useQueryAuthGovBrGetUrl } from '@/api/auth/govbr/queries'
import LogoFNDE from '@/assets/svg/fnde/mono.svg'
import LogoSPA from '@/assets/svg/spa/mono.svg'
import { getErrorDetails } from '@/utils/error'
import { stringToBoolean } from '@/utils/string'

const Login: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [kidImage, setKidImage] = useState('')
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const hasDevLogin = stringToBoolean(process.env.REACT_APP_DEV_LOGIN)

  const { mutate: postAuthGovBr } = useMutationPostAuthGovBr({
    onSuccess: () => {
      navigate('/')
    },
    onError: error => {
      const statusCode = error.response?.status

      if (statusCode === 401) {
        navigate('/login/acesso-negado')

        return
      }

      toast({
        status: 'error',
        title: 'Ops!',
        description: getErrorDetails(error),
        isClosable: true,
      })

      searchParams.delete('code')
      searchParams.delete('state')

      setSearchParams(searchParams)

      setIsLoggingIn(false)
    },
  })

  const {
    isFetching: isFetchingAuthGovBrUrl,
    isRefetching: isRefetchingAuthGovBrUrl,
    refetch: refetchAuthGovBrUrl,
  } = useQueryAuthGovBrGetUrl({
    enabled: false,
    onSuccess: url => {
      toast({
        status: 'success',
        title: 'Redirecionando',
        description: 'Você será redirecionado para o gov.br',
        isClosable: true,
      })

      window.location.href = url
    },
    onError: error => {
      toast({
        status: 'error',
        title: 'Ops!',
        description: getErrorDetails(error),
        isClosable: true,
      })
    },
  })

  const handleLoginGovBr = () => {
    refetchAuthGovBrUrl()
  }

  const handleLoginDevelopment = () => {
    navigate('/login/desenvolvedor')
  }

  useEffect(() => {
    const code = searchParams.get('code')
    const state = searchParams.get('state')

    if (code && state) {
      postAuthGovBr({ code, state })

      setIsLoggingIn(true)
    }

    let currentIndex = 0
    let foundImage = true

    const loadImage = async () => {
      while (foundImage) {
        try {
          await import(`@/assets/img/kids/kid-${currentIndex}.png`)
          currentIndex++
        } catch {
          foundImage = false
        }
      }

      const randomIndex = Math.floor(Math.random() * currentIndex)
      const selectedImage = await import(
        `@/assets/img/kids/kid-${randomIndex}.png`
      )

      setKidImage(selectedImage.default)
    }

    loadImage()
  }, [])

  return (
    <Flex height="100vh">
      <Hide below="lg">
        <Flex
          direction="column"
          height="100%"
          backgroundColor="brand.primary.dark_2"
          overflow="hidden"
          flex={1}
        >
          <Grid templateColumns="1fr" templateRows="1fr 2fr" height="100%">
            <GridItem
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-end"
              paddingX="8"
              paddingTop="16"
              paddingBottom="8"
            >
              <Flex direction="column" gap={4}>
                <Image src={LogoFNDE} width="160px" />
                <Typography
                  variant="d-sm"
                  color="brand.neutral.white"
                  textAlign="left"
                >
                  Transformando vidas
                  <br /> por meio da educação.
                </Typography>
              </Flex>
            </GridItem>
            <GridItem
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Image src={kidImage} height="600px" objectFit="cover" />
            </GridItem>
          </Grid>
        </Flex>
      </Hide>
      <Flex
        direction="column"
        justify="center"
        align="center"
        paddingX="8"
        flex={1}
        gap={isLoggingIn ? 8 : 16}
        backgroundColor="brand.highlight.dark"
      >
        <Image src={LogoSPA} width="300px" />
        {isLoggingIn ? (
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap="4"
            width="full"
          >
            <Progress
              size="sm"
              width="40%"
              borderRadius="1rem"
              isIndeterminate
            />
            <Typography variant="b-lg" color="brand.neutral.white">
              Estamos verificando suas credenciais...
            </Typography>
          </Flex>
        ) : (
          <Flex direction="column" gap="4" width="full" maxWidth="24rem">
            <Button
              label="Entrar com gov.br"
              width="full"
              backgroundColor="brand.primary.dark_2"
              _hover={{ backgroundColor: 'brand.primary.dark_1' }}
              isLoading={isFetchingAuthGovBrUrl || isRefetchingAuthGovBrUrl}
              onClick={handleLoginGovBr}
            />
            {hasDevLogin ? (
              <Button
                label="Entrar como desenvolvedor"
                width="full"
                backgroundColor="#A82E2F"
                _hover={{ backgroundColor: '#C63637' }}
                onClick={handleLoginDevelopment}
              />
            ) : null}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default Login
