import React, { useMemo } from 'react'

import { Text } from '@chakra-ui/react'
import {
  Flex,
  Stack,
  TColumnDef,
  DataGrid,
  Box,
  Typography,
} from '@pnld/components-web'

import { IData, ITableProps } from './types'

const stagesMap = {
  educacaoInfantil: 'Educação Infantil',
  ensinoFundamental: 'Ensino Fundamental',
  ensinoMedio: 'Ensino Médio',
  cursoTecnico: 'Curso Técnico',
}

const StagesTable = ({ id, data, footerData }: ITableProps) => {
  const columns: Array<TColumnDef<IData>> = [
    {
      accessorKey: 'stage',
      header: `Etapas de ensino - ${stagesMap[id] || ''}`,
    },
    {
      accessorKey: 'total',
      header: 'Censo',
    },
    {
      accessorKey: 'h1',
      header: 'H1',
    },
    {
      accessorKey: 'h2',
      header: 'H2',
    },
  ]

  return (
    <Stack gap={0}>
      <DataGrid columns={columns} data={data} hasPagination={false} />
      <Flex
        justify="flex-end"
        bg="brand.primary.light_2"
        p={4}
        color="brand.neutral.dark_2"
        gap={8}
        sx={{
          marginTop: '0 !important',
        }}
      >
        <Flex flex={1} justify="flex-end">
          <Text fontWeight="bold" fontSize="2xs">
            Total
          </Text>
        </Flex>
        <Flex flex={1} justify="space-around">
          <Text fontWeight="bold" fontSize="2xs">
            {footerData.total}
          </Text>
          <Text fontWeight="bold" fontSize="2xs">
            {footerData.h1}
          </Text>
          <Text fontWeight="bold" fontSize="2xs">
            {footerData.h2}
          </Text>
        </Flex>
      </Flex>
    </Stack>
  )
}

export default StagesTable
