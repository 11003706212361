import React, { useRef, useState, useMemo, useCallback } from 'react'

import { FormikProps, FormikValues } from 'formik'

import { IOptionProps, useToast } from '@pnld/components-web'

import { useQueryGetFiles } from '@/api/file/queries'
import { TGetFilesParams, TGetFilesResponse } from '@/api/file/types'
import useDebounce from '@/hooks/useDebounce'

const usePaginationController = (
  filesData: TGetFilesResponse | undefined,
  setFilters: React.Dispatch<React.SetStateAction<TGetFilesParams>>
) => {
  const itemsPerPageOptions: Array<IOptionProps> = useMemo(
    () => [
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '30', value: 30 },
      { label: '40', value: 40 },
      { label: '50', value: 50 },
    ],
    []
  )

  const pageJumpOptions: Array<IOptionProps> = useMemo(() => {
    const totalPages = filesData?.pagination.totalPages || 1
    return Array.from({ length: totalPages }, (_, i) => ({
      label: `${i + 1}`,
      value: i,
    }))
  }, [filesData?.pagination.totalPages])

  const handleSelectNextPage = () => {
    setFilters(prev => ({
      ...prev,
      page: (prev.page || 1) + 1,
    }))
  }

  const handleSelectPreviousPage = () => {
    setFilters(prev => ({
      ...prev,
      page: Math.max((prev.page || 1) - 1, 1),
    }))
  }

  const handleChangeItemsPerPage = (itemsPerPage: number) => {
    setFilters(prev => ({
      ...prev,
      pageSize: itemsPerPage,
      page: 1,
    }))
  }

  const handleJumpToPage = (newPageIndex: number) => {
    setFilters(prev => ({
      ...prev,
      page: newPageIndex + 1,
    }))
  }

  return {
    paginationProps: {
      currentPageIndex: (filesData?.pagination.currentPage || 1) - 1,
      itemsPerPage: filesData?.pagination.pageSize || 10,
      numberOfPages: filesData?.pagination.totalPages || 1,
      numberOfItems: filesData?.pagination.count || 0,
      choicesOfItemsPerPage: itemsPerPageOptions,
      choicesOfPageJump: pageJumpOptions,
      onSelectPreviousPage: handleSelectPreviousPage,
      onSelectNextPage: handleSelectNextPage,
      onJumpToPage: handleJumpToPage,
      onChangeItemsPerPage: handleChangeItemsPerPage,
      disableGoToPreviousPage: (filesData?.pagination.currentPage || 1) <= 1,
      disableGoToNextPage:
        (filesData?.pagination.currentPage || 1) >=
        (filesData?.pagination.totalPages || 1),
    },
  }
}

const useFileController = () => {
  const toast = useToast()
  const formikRef = useRef<FormikProps<FormikValues>>(null)
  const [filters, setFilters] = useState<TGetFilesParams>({
    page: 1,
    pageSize: 10,
  })

  const { data: filesData, isLoading } = useQueryGetFiles(filters)

  const tableData = useMemo(() => {
    if (!filesData?.results) return []
    return filesData.results.map(file => ({
      id: file.coUploadedFile,
      filename: file.fileName,
      username: file.coUser.noUserName || file.coUser.dsGovbrEmail,
      sendDate: new Date(file.dtCreatedAt)
        .toISOString()
        .split('T')[0]
        .split('-')
        .reverse()
        .join('/'),
    }))
  }, [filesData?.results])

  const updateFilters = useCallback((newFilters: Partial<TGetFilesParams>) => {
    setFilters(prev => ({
      ...prev,
      ...newFilters,
      page: 1,
    }))
  }, [])

  const debouncedUpdateFilters = useDebounce<Partial<TGetFilesParams>>(
    updateFilters,
    500
  )

  const handleSearchChange = (search: string) => {
    debouncedUpdateFilters({ fileName: search || undefined })
  }

  const handleStartDateChange = (date: string) => {
    debouncedUpdateFilters({ startDate: date || undefined })
  }

  const handleFinalDateChange = (date: string) => {
    debouncedUpdateFilters({ finalDate: date || undefined })
  }

  const handleSendData = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (formikRef.current) {
      formikRef.current.handleSubmit()
    }
  }

  const handleSubmit = (values: FormikValues) => {
    toast({
      title: 'Dados enviados com sucesso',
      status: 'success',
      duration: 3000,
      isClosable: true,
    })
  }

  return {
    fileProps: {
      formikRef,
      handleSearchChange,
      handleStartDateChange,
      handleFinalDateChange,
      sendData: handleSendData,
      handleSubmit,
      tableData,
      isLoading,
      toast,
    },
    filesData,
    setFilters,
  }
}

export const useSendingsController = ({ onClose }: { onClose: () => void }) => {
  const { fileProps, filesData, setFilters } = useFileController()
  const { paginationProps } = usePaginationController(filesData, setFilters)

  return {
    ...fileProps,
    ...paginationProps,
  }
}
