import React, { useState, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { Stack, Flex } from '@chakra-ui/react'
import { Spinner } from '@pnld/components-web'

import { getAuthIsAuthenticated } from '@/api/auth/isAuthenticated/services'
import { useUser } from '@/contexts/UserContext'
import { useBreadcrumbProps } from '@/data/template'
import { useSidebarItems } from '@/data/template/hooks'
import { stringToBoolean } from '@/utils/string'

import Breadcrumbs from './parts/Breadcrumbs'
import Sidebar from './parts/Sidebar'

const TemplatePage: React.FC = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const breadcrumbProps = useBreadcrumbProps()
  const sidebarItems = useSidebarItems()
  const { isLoading } = useUser()

  useEffect(() => {
    async function checkAuthentication() {
      getAuthIsAuthenticated()
        .then(() => {
          setIsAuthenticated(true)
        })
        .catch(() => {
          setIsAuthenticated(false)
        })
        .finally(() => {
          setIsAuthenticating(false)
        })
    }

    if (!stringToBoolean(process.env.REACT_APP_DISABLE_AUTH)) {
      checkAuthentication()
    } else {
      setIsAuthenticated(true)
      setIsAuthenticating(false)
    }
  }, [])

  if (isAuthenticating || isLoading) {
    return (
      <Flex align="center" justify="center" w="100" h="100vh">
        <Spinner size="xl" color="brand.highlight.dark_2" />
      </Flex>
    )
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  return (
    <Sidebar {...sidebarItems}>
      <Stack flexGrow={1} spacing="4">
        <Flex>
          <Breadcrumbs {...breadcrumbProps} />
        </Flex>
        <Outlet />
      </Stack>
    </Sidebar>
  )
}

export default TemplatePage
