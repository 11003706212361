import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Flex, Grid, Stack, Input, InputGroup } from '@chakra-ui/react'
import {
  DataGrid,
  TColumnDef,
  IconButton,
  Typography,
  Button,
  useModal,
  Tag,
  Pagination,
  Form,
  Select,
} from '@pnld/components-web'

import TotalizerDisplay from '@/components/Display/Totalizer'
import { useUser } from '@/contexts/UserContext'

import {
  IUsersTableData,
  useUsersPageController,
  useTotalizersController,
  useProfilesController,
} from './controller'
import CreateUserModal from './CreateUserModal'
import {
  TableSkeleton,
  TotalizerSkeleton,
  PaginationSkeleton,
  EmptyState,
} from './skeletonUsers'

const ActionButtons = (row: IUsersTableData) => {
  const { id } = row
  const navigate = useNavigate()

  const { dsCpf } = useUser()

  if (dsCpf === row.dsCpf) {
    return null
  }

  const handleEdit = () => {
    navigate(`/usuarios/editar/${id}`)
  }

  return (
    <Flex>
      <IconButton
        aria-label="Editar"
        iconName="edit"
        variant="tertiary"
        onClick={handleEdit}
      />
    </Flex>
  )
}

const UserStatusTag = (row: IUsersTableData) => {
  const { dsStatus } = row

  return (
    <Tag variant={dsStatus === 'Ativo' ? 'positive' : 'negative'}>
      {dsStatus}
    </Tag>
  )
}

const Users = () => {
  const { isOpen, onClose, onOpen } = useModal()
  const {
    tableData,
    isLoading,
    handleSearchChange,
    handleProfileChange,
    handleStatusChange,
    refetch: refetchUsers,
    ...paginationProps
  } = useUsersPageController({ onClose })

  const {
    totalizers,
    isLoading: isTotalizersLoading,
    refetch: refetchTotalizers,
    handleSearchChange: handleSearchChangeTotalizers,
    handleProfileChange: handleProfileChangeTotalizers,
    handleStatusChange: handleStatusChangeTotalizers,
  } = useTotalizersController()

  const {
    visibleProfiles,
    profileOptions,
    isLoading: isProfilesLoading,
  } = useProfilesController()

  const statusOptions = [
    { label: 'Ativo', value: 'Ativo' },
    { label: 'Inativo', value: 'Inativo' },
  ]

  const refetchAll = async () => {
    await Promise.all([refetchUsers(), refetchTotalizers()])
  }

  const columns = useMemo<Array<TColumnDef<IUsersTableData>>>(
    () => [
      {
        accessorKey: 'dsName',
        header: 'Nome do usuário',
      },
      {
        accessorKey: 'profile',
        header: 'Perfil',
      },
      {
        cell: ({ row }) => UserStatusTag(row.original),
        header: 'Status',
      },
      {
        cell: ({ row }) => ActionButtons(row.original),
        header: 'Ações',
      },
    ],
    []
  )

  return (
    <Flex direction="column" w="100%" gap={4}>
      <Form onSubmit={() => {}}>
        <Flex w="full" gap={4} p={4} borderRadius="md" boxShadow="md">
          <Flex align="center">
            <Typography
              variant="b-sm"
              color="brand.primary.dark_1"
              whiteSpace="nowrap"
            >
              Filtrar por
            </Typography>
          </Flex>
          <Box w="full">
            <InputGroup gap={4}>
              <Input
                placeholder="Buscar por usuário"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleSearchChange(e.target.value)
                  handleSearchChangeTotalizers(e.target.value)
                }}
              />
              <Select
                name="profile"
                placeholder="Selecione o perfil"
                options={profileOptions}
                onChange={(newValue, actionMeta) => {
                  handleProfileChange(newValue, actionMeta)
                  handleProfileChangeTotalizers(newValue, actionMeta)
                }}
              />
              <Select
                name="status"
                placeholder="Selecione o status"
                options={statusOptions}
                onChange={(newValue, actionMeta) => {
                  handleStatusChange(newValue, actionMeta)
                  handleStatusChangeTotalizers(newValue, actionMeta)
                }}
              />
            </InputGroup>
          </Box>
        </Flex>
      </Form>

      <Grid
        templateColumns={
          isTotalizersLoading
            ? 'repeat(5, 1fr)'
            : `repeat(${visibleProfiles.length + 2}, 1fr)`
        }
        gap={4}
        w="100%"
        h="100%"
      >
        {isTotalizersLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              <TotalizerSkeleton key={index} />
            ))
          : totalizers.map(totalizer => (
              <Box
                key={totalizer.label}
                h="100%"
                w="100%"
                bg="white"
                borderRadius="md"
                boxShadow="sm"
                overflow="hidden"
              >
                <TotalizerDisplay {...totalizer} />
              </Box>
            ))}
      </Grid>

      <Stack
        flexGrow={1}
        flex={1}
        p={5}
        boxShadow="md"
        borderRadius="base"
        gap={4}
      >
        <Flex justify="space-between" align="center">
          <Typography variant="h-xs" color="brand.primary.dark_1">
            Usuários do sistema
          </Typography>
          <Button
            label="Adicionar usuário"
            iconName="plus"
            onClick={onOpen}
            border="square"
          />
        </Flex>

        <Box overflowX="auto" flexGrow={1}>
          {isLoading ? (
            <TableSkeleton />
          ) : tableData.length === 0 ? (
            <EmptyState />
          ) : (
            <DataGrid
              columns={columns}
              data={tableData}
              hasPagination={false}
            />
          )}
        </Box>

        {isLoading ? (
          <PaginationSkeleton />
        ) : tableData.length > 0 ? (
          <Pagination {...paginationProps} />
        ) : null}

        <CreateUserModal
          isOpen={isOpen}
          onClose={onClose}
          refetchData={refetchAll}
        />
      </Stack>
    </Flex>
  )
}

export default Users
