import React, { useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { useToast } from '@pnld/components-web'

import {
  useQueryGetPredictionsYears,
  useQueryGetEducationStages,
} from '@/api/auxiliaries/queries'
import { useQueryGetPredictionsPreview } from '@/api/predictions/queries'
import { IEducationStageDTO } from '@/DTOs/auxiliaries/auxiliaries.DTO'
import { getErrorDetails } from '@/utils/error'

const emptySearch = {
  qtCensus: 0,
  qtH1: 0,
  qtH2: 0,
  qtEntities: 0,
  teachingSteps: {},
}

export const allSpheresValues = ['PUBLICA', 'PRIVADA', 'PRIVADA_CONVENIADA']
export const allStagesValues = [
  'CURSO_TECNICO',
  'EDUCACAO_INFANTIL',
  'EJA',
  'ENSINO_FUNDAMENTAL_INICIAL',
  'ENSINO_FUNDAMENTAL_FINAL',
  'ENSINO_MEDIO',
]

export const usePredictionsController = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const checkFirstRender = useRef(true)
  const [availableUntilYear, setAvailableUntilYear] = React.useState<number>(0)
  const [isSearchEmpty, setIsSearchEmpty] = React.useState(false)
  const [predictionsFilters, setPredictionsFilters] = React.useState({
    coEducationStage: '',
    nuYearPrediction: '',
    dsSphere: '',
    dsEducationGroup: '',
  })
  const {
    data: predictionsPreview,
    refetch: refetchPredictionsPreview,
    isFetching: isPredictionsPreviewLoading,
  } = useQueryGetPredictionsPreview(predictionsFilters, {
    enabled: false,
    onSuccess: data => {
      if (JSON.stringify(data) === JSON.stringify(emptySearch)) {
        setIsSearchEmpty(true)
        return
      }
      const spheres = predictionsFilters.dsSphere
        ? predictionsFilters.dsSphere.replaceAll(',', ', ')
        : allSpheresValues.join(', ')
      const stages = getStagesFiltered()

      navigate('/predicoes/resultado', {
        state: {
          predictionsPreview: data,
          filters: predictionsFilters,
          spheres,
          educationStages: stages,
        },
      })
    },
  })
  const { data: educationStages, isLoading: isEducationStagesLoading } =
    useQueryGetEducationStages({
      onError: error => {
        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro',
          description: getErrorDetails(error),
          isClosable: true,
        })
      },
    })
  const { data: predictionYears, isLoading: isPredictionYearsLoading } =
    useQueryGetPredictionsYears({
      onError: error => {
        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro',
          description: getErrorDetails(error),
          isClosable: true,
        })
      },
    })

  const getStagesFiltered = () => {
    if (predictionsFilters.coEducationStage) {
      return 'DIVERSOS'
    }

    return predictionsFilters.dsEducationGroup
      ? predictionsFilters.dsEducationGroup.replaceAll(',', ', ')
      : allStagesValues.join(', ')
  }

  useEffect(() => {
    if (checkFirstRender.current) {
      checkFirstRender.current = false
      return
    }

    refetchPredictionsPreview()
  }, [predictionsFilters])

  const predictionYearsOptions = useMemo(() => {
    const maxYear = Math.max(...(predictionYears || [0]))
    setAvailableUntilYear(maxYear)

    return (predictionYears || []).map(year => ({
      label: String(year),
      value: year,
    }))
  }, [predictionYears])

  return {
    predictionsPreview,
    refetchPredictionsPreview,
    isPredictionsPreviewLoading,
    educationStages,
    isEducationStagesLoading,
    availableUntilYear,
    predictionYearsOptions,
    isPredictionYearsLoading,
    setPredictionsFilters,
    isSearchEmpty,
    setIsSearchEmpty,
  }
}
