import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios'
import applyCaseMiddleware from 'axios-case-converter'

import { IErrorDTO } from '@/DTOs/errorDTO'

export const http = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  })
)

export const httpWithoutMiddleware = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
})

http.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  return request
})

http.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  error => {
    if (error.response) {
      const path = error.response.config.url
      const status = error.response.status

      if (status === 401 && !path?.includes('/login')) {
        const errorResponse = error.response.data as IErrorDTO
        const errors = errorResponse.errors.map(error => error.code)

        if (errors.includes('not_authenticated')) {
          window.location.href = '/login'
        }
      }
    }

    return Promise.reject(error)
  }
)

export default http
